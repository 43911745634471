.breadcrumb {
  padding-left: 20px;

  .breadcrumb-chevron {
    width: 10px;
    margin-right: 10px !important;
  }

  .breadcrumb-link {
    &:hover {
      cursor: pointer;
    }
  }

  li {
    color: white;
  }

  @media (max-width: 767px) {
    display: none;
  }
}