.left-panel {
  min-height: calc(100vh - 180px);

  .menu-left-mobile {
    display: none;
  }

  @media (max-width: 767px) {
    background-color: white;
    box-shadow: 0px 4px 5px #b2b2b2;
    position: absolute;
    height: 100%;
    top: 0;
    min-width: 80vw;
    z-index: 100;

    .menu-left-mobile {
      display: block;
      position: absolute;
      right: -27px;
      border: 0;
      height: 30px;
      width: 30px;
      background-color: white;
      border-radius: 3px;
      box-shadow: 3px 3px 3px #e2e1e1;
    }

    &.collapsed {
      min-width: 10px;
      width: 10px;
      max-width: 10px;

      div {
        display: none;
      }
    }
  }

  .category-level2, .category-level3 {
    /*display: flex;*/
    display: block ruby;
    text-overflow : clip;
    overflow: hidden;
  }

  .left-panelButton {
    padding: 5px 5px;
    border: 0;
    background: none;

    &.collapsed > .fa-angle-down {
      rotate: -90deg;
      transition-duration: .8s;
    }

    &:hover {
      color: var(--primary);
    }
  }

  ul {
    list-style-type: none;
    padding-left: 10px;
  }

  .collapse.show {
    background-color: var(--secondary);
  }

  .category-level1.active {
    background-color: var(--primary);
    button {
      color: white;
    }
  }

  .categories-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    i {
      color: var(--primary);
      font-size: 5em;
    }
  }
}