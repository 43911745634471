.myaccount {
  .container {
    margin-top: 40px;
  }

  .myaccount-nav {
    display: flex;

    li {
      color: white;
      &:hover {
        cursor: pointer;
      }

      &.active {
        text-decoration: underline;
      }
    }

    @media (max-width: 767px) {
      padding-left: 5px;
      font-size: 14px;
      justify-content: space-between;
      width: 100%;
    }
  }
}

table td {
  padding-bottom: 10px;
}

hr {
  margin: 40px;
}

.delivery-title {
  margin-bottom: 2em;
}