.container-modal {
  padding: 30px;

  @media (max-width: 767px) {
    padding: 10px;
  }
}

.modal-orderDetail {
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  min-width: 70%;
  max-width: 80%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  h3 {
    color: var(--primary);
  }

  .modal-ordeDetailTotal {
    display: flex;
    justify-content: space-between;
    padding: 0 35px;
  }

  .orderDetail-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 20px 0;

    i {
      color: var(--primary);
      font-size: 5em;
    }
  }

  .modal-orderDetailRow {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    min-width: inherit;
    max-width: inherit;
    left: inherit;
  }
}