body, button {
  margin: 0;
  font-family: 'Baloo Tamma 2', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --primary: #1481BA;
  --secondary: #1481ba47;
  --accent: #E6006B;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  position: relative;
  min-height: 100vh;
  padding-bottom: 60px;
}

h1 {
  margin-bottom: 30px;
}

a {
  text-decoration: none;
  color: #000;

  &:hover {
    color: var(--primary);
  }

  &.active {
    color: var(--primary);
    font-weight: 500;
  }
}

.btn-white {
  background: 0;
  border: 0;
  text-decoration: none;
}

.error {
  color: red;
}

.ReactModal__Overlay {
  z-index: 1000;
}
