.container-modal {
  padding: 30px;

  @media (max-width: 767px) {
    padding: 10px;
  }
}

.modal-login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  outline: none;
  
  .container-modal {
    width: 100%;
  }

  h3 {
    color: var(--primary);
  }

  .modal-loginSubmit {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}