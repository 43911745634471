.right-panel {
  width: 250px;
  display: none;
  border-left: 1px solid #b2b2b2;
  padding: 10px;
  position: absolute;
  background-color: white;
  right: 0;
  z-index: 1000;
  min-height: calc(100% - 200px);
  max-height: calc(100% - 200px);
  overflow: scroll;

  .cart-container {
    overflow: scroll;
    height: calc(100% - 140px);
  }
  
  .cart-message {
      font-weight: bold;
      color: red;
  }

  &.opened {
    display: block
  }
}