.products {
  min-height: calc(100vh - 150px);

  .products-panel {
    margin: 1rem;
    width: -webkit-fill-available;
    min-height: calc(100vh - 60px);
    overflow: scroll;
    position: relative;

    @media (max-width: 767px) {
      min-width: 100%;
    }
  }

  .products-parcelAdd {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }

  .products-itemsPerPage {
    display: flex;
    justify-content: flex-end;
  }

  .sub-header-tab {
    border: 0;
    background-color: var(--secondary);
    color: var(--primary);
    margin-right: 2px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 3px 8px;
    height: 35px;

    &.is-active {
      background-color: var(--primary);
      color: white;
      font-weight: 500;
    }
  }

  .products-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    i {
      color: var(--primary);
      font-size: 5em;
    }
  }

  .product-cartCount {
    color: white;
    background-color: var(--accent);
    border-radius: 50%;
    font-size: 12px;
    height: 13px;
    width: 13px;
    display: flex;
    line-height: 15px;
    justify-content: center;
    position: absolute;
    top: -3px;
    left: -7px;
  }

  .product-resizer {
    background-color: #d2d2d2;
    cursor: col-resize;
    display: flex;
    align-items: center;

    i {
      color: black;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  .product-panel-container {
    display: flex;

    @media (max-width: 767px) {
      position: relative;
      display: block;
    }
  }
  .product-detail {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 125px;
    left: 5px;
    border-radius: 50%;
    font-size: 18px;
    background-color: white;
    opacity: .8;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 1;
      cursor: zoom-in;
    }
  }

  .product-labelFrance {
    content: "";
    height: 30px;
    width: 30px;
    position: absolute;
    top: 130px;
    right: 10px;
    background-image: url("../../assets/prod-france.png");
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }
  .product-labelHortisud {
    content: "";
    height: 30px;
    width: 30px;
    position: absolute;
    top: 130px;
    right: 74px;
    background-image: url("../../assets/hortisud-flag-icon.jpg");
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }
  .product-labelFDF {
    content: "";
    height: 30px;
    width: 30px;
    position: absolute;
    top: 130px;
    right: 42px;
    background-image: url("../../assets/fdf-flag-icon.jpg");
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }
  .products-container {
    padding: 20px;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding-bottom: 10px;
  }
}

.sub-menu {
  display: flex;
  button {
    background-color: transparent;
    border: 0;
    color: white;

    &:hover {
      color: var(--accent);
      opacity: 0.8;
    }
  }

  .completed {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      top: -2px;
      left: 1px;
      border-radius: 4px;
      background-color: var(--accent);
    }
  }

  @media (max-width: 767px) {
    justify-content: space-between;
    padding-left: 10px;
    width: 100%;
  }
}

.sub-header {
  height: 35px;
  display: flex;
  align-items: center;
  background-color: var(--primary);
  font-weight: 500;
  box-shadow: 0px 4px 5px #b2b2b2;
  justify-content: space-between;

  i {
    margin-right: 5px;
  }

  ul {
    margin: 0;
  }

  a {
    color: white;
  }

  li {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }

  @media (max-width: 768px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-scrolling: none;
    scrollbar-width: none;
  }
}

.product-imageModal {
  position: absolute;
  top: 0;
  width: 100%;
  margin: auto;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #504f4fdb;
  height: 100%;

  img {
    width: 80%;
  }
}

.sub-header-container {
  @media (max-width: 768px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-scrolling: none;
    scrollbar-width: none;
  }

  .sub-header-tab {
    @media (max-width: 768px) {
      flex: 0 0 auto;
    }
  }
}

.row {
  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
}