.container-modal {
  padding: 30px;

  @media (max-width: 767px) {
    padding: 10px;
  }
}

.modal-delivery {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 90%;
  width: 800px;
  outline: none;
  max-height: 90vh;
  overflow-y: auto;
  
  .container-modal {
    width: 100%;
  }

  .modal-deliverySubtitle {
    margin-bottom: 20px;
    text-decoration: underline;
  }

  pre {
    white-space: pre-wrap;
    font-size: 0.675em;
  }

  @media (max-width: 767px) {
    max-width: 100vw;
    left: inherit;
    transform: inherit;
    top: inherit;
  }

  h3 {
    color: var(--primary);
  }

  .delivery-loading {
    text-align: center;
    font-size: xxx-large;
  }

  .modal-deliveryRow {
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .modal-deliveryRowComments {
      color: red;
      font-weight: bold;
  }
  
  .wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .modal-deliveryItem {
    border-radius: 5px;
    padding: 5px;

    &:hover {
      cursor: pointer;
      background-color: var(--secondary);
    }

    &.selected {
      border: 1px solid var(--primary);
      background-color: var(--secondary);
    }
  }

  .modal-deliveryContainerRow {
    margin-bottom: 20px;
  }

  .modal-deliverySubmit {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    
    button {
      min-width: 150px;

      @media (max-width: 767px) {
        min-width: 110px;
      }
    }
  }
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

