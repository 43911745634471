.contact {
  .contact-paragraph {
    margin-bottom: 70px;
  }
  
  a {
    color: var(--primary) !important;
  }

  iframe {
    min-height: 400px;
  }
}
