.container-modal {
  padding: 30px;

  @media (max-width: 767px) {
    padding: 10px;
  }
}

.modal-productDetail {
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  min-width: 500px;
  max-width: 80%;
  position: absolute;
  left: 30%;
  transform: translate(0%, 30%);

  h3 {
    color: var(--primary);
  }

  .modal-productDetailRow {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    min-width: inherit;
    max-width: inherit;
    left: inherit;
  }
}