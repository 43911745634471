.footer {
  position: absolute;
  bottom: 0;
  padding: 0 30px;
  width: 100%;
  background-image: url("../../assets/bottom.png");
  background-size: 100vw 80px;
  background-repeat: no-repeat;
  height: 65px;
  margin-top: 50px;
  
  .footer-right {
    display: flex;
    justify-content: flex-end;
    height: 90%;
    align-items: flex-end;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-left: 30px;

      a {
        color: #fff;
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}