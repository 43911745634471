.field-password {
  position: relative;

  i {
    position: absolute;
    right: 8px;
    top: 54%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}