.search-form {
  display: flex;
  align-items: center;
  height: 40px;

  button {
    position: relative;
    border: 0;
    background-color: var(--secondary);
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: var(--primary);
    }

    &.menu {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &.glass {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .dotted {
    width: 10px;
    height: 10px;
    content: "";
    background-color: var(--accent);
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: 24px;
  }

  .search-length {
    display: flex;
    align-items: center;
    margin-bottom: 20px !important;

    .MuiFormControl-root {
      margin-left: 10px;
    }
  }

  .clear  {
    margin-right: 25px;

    &:hover {
      cursor: pointer;
    }
  }

  div {
    margin: 0;
    border-radius: 0;
  }

  .dropdown-menu {
    width: 100%;
    padding: 20px;

    li {
      margin: 0;
    }
  }
}

.dropdown-menu {
  z-index: 1100;
}