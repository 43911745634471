.products-card {
    position: relative;
    border-radius: 10px;
    border-bottom-style: solid !important;
    border-bottom-width: 10px !important;
    border: var(--primary);
    padding: 0;
    min-height: 285px;
    box-shadow: rgba(0,0,0,0.25) 0px 3px 11px 0px;

    .slick-prev {
        left: 5px;
        z-index: 1000;
    }
    .slick-next {
        right: 5px;
        z-index: 1000;
    }

    .product-cardImage {
        width: 100%;
        height: 160px;
        background-color: lightgray;
    }

    .product-imageContainer {
        position: relative;
        overflow: hidden;
    }

    .product-cardImageFull {
        width: 100%;
        height: 160px;
        object-fit: cover;

        &:hover {
            cursor: pointer;
            opacity: 0.5;
        }
    }

    .zoom {
        transform: scale(1.5);
        transform-origin: center center;
    }

    .product-cardContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        span {
            width: 30px;
            border-top: 1px solid var(--primary);
            border-bottom: 1px solid var(--primary);
            text-align: center;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            color: var(--primary);
        }

        i {
            font-size: 15px;
            color: var(--primary);
        }
    }

    .products-cardAdd, .products-cardCart {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border: 1px solid var(--primary);
        background-color: white;

        &:hover {
            background-color: var(--secondary);
        }        

        i {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.disabled {
            opacity: 0.5;

            i {
                opacity: 0.5;
            }
        }
    }

    .products-cardAdd {
        width: 30px;
        height: 28px;
        padding: 0;
    }

    .products-cardCart {
        border: 1px solid var(--primary);
        border-radius: 5px;
        background-color: white;
        color: var(--primary);

        &.disabled {
            opacity: 0.5;

            i {
                opacity: 0.5;
            }
        }

        i {
            font-size: 15px;
            color: var(--primary);
        }
    }

    .product-cardDescription {
        padding: 10px;
    }
    
    .product-cardInCart {
        color:red;
    }
}
