.home-col {
  text-align: center;

  img {
    width: 70%
  }
}

.home-image {
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-row {
  margin-bottom: 20px;
}