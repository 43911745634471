.cart-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;

  img {
    margin-right: 5px;
  }

  .cart-itemTotalTrash {
    display: flex;

    .cart-itemTotal {
      margin: 0 7px;
    }
  }

  .cart-itemImage {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }

  .cart-itemText {
    max-height: 36px;
    overflow: hidden;
  }

  .cart-itemDescription {
    display: flex;
    align-items: center;

    i {
      font-size: 30px;
      color: var(--primary);
      margin-right: 10px; 
    }
  }

  .cart-trash {
    border: 1px solid var(--primary);
    border-radius: 5px;
    background-color: white;
    color: var(--primary);
    display: flex;
    align-items: center;
    padding: 8px;
  
    i {
      color: var(--primary);
    }
  }
}

