.header {
  
  margin-bottom: 50px;
  padding: 0 30px;
  box-shadow: 0px 4px 5px #b2b2b2;
  position: relative;

  @media (min-width: 768px) {
    height: 80px;
  }

  &.mod-noMargin {
    margin: 0;
    box-shadow: none;
  }

  .header-mobile {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

    @media (min-width: 768px) {
      display: none;
      justify-content: space-between;
    }
  }

  .header-right {
    display: flex;
    align-items: center;
  }

  .header-toggler {
    height: 40px;
    width: 40px;
    border-radius: 5px;
    border: 2px solid var(--primary);
    background-color: #fff;
  }

  .header-toggler-icon {
    color: #001021;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .collapse {
    position: absolute;
    top: 80px;
    width: 100vw;
    left: 0;
    background: white;
    border-bottom: 1px solid #ccc;
    z-index: 1000;

    a {
      height: 35px;
      font-size: 25px;
      display: block;
      line-height: 35px;
      margin: 10px 0;
    }
  }

  .header-desktop {
    display: none;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @media (min-width: 768px) {
      display: flex;
    }
  }

  .header-logo {
    height: 60px;
    width: auto;
  }

  .header-left {
    display: flex;
    align-items: center;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-left: 30px;
    }
  }

  .header-right {
    button {
      color: var(--primary);
    }
    i {
      color: var(--primary);
      margin-right: 10px;
    }
  }

  .dropdown-item {
    &.active {
      background-color: #ccc !important;
    }

    &:active {
      background-color: var(--primary) !important;
    }
  }
}

.mobile-bottom-nav {
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    height: 60px;
    padding: 0 10px;
    
    .nav-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--primary);
      text-decoration: none;
      font-size: 0.8rem;
      padding: 8px 0;
      position: relative;
      z-index: 1;
      
      i {
        font-size: 1.2rem;
        margin-bottom: 4px;
      }
      
      &.active {
        color: var(--accent);
      }

      &.nav-item-center {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-of-type(2) {
        margin-right: 20px;
      }
      &:nth-of-type(4) {
        margin-left: 20px;
      }
    }

    .nav-logo {
      position: absolute;
      left: 50%;
      bottom: 20px;
      transform: translateX(-50%);
      width: 60px;
      height: 60px;
      background: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 1px -6px 5px rgba(0, 0, 0, 0.1);
      z-index: 2;

      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }
    }
  }
}

